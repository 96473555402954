<template>
  <component
    :class="$style.root"
    :data-view-option="props.viewOption"
    :data-size-option="props.sizeOption"
    :data-uppercase="props.toUpperCase ? '' : null"
    :is="component"
    :type="linkType"
    viewOption="transparent"
  >
    <slot />
  </component>
</template>

<script setup>
  import i18nLink from '~/components/i18n/link'
  import i18nLinkWithFallback from '~/components/i18n/link-with-fallback'
  import { NuxtLink } from '#components'

  const props = defineProps({
    viewOption: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'primary', 'secondary', 'reversed'].includes(value),
    },
    sizeOption: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'narrow', 'tiny'].includes(value),
    },
    componentType: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'link', 'linkWithFallback', 'nuxtLink', 'div', 'a'].includes(value),
    },
    linkType: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'dynamic'].includes(value),
    },
    toUpperCase: {
      type: Boolean,
      default: true,
    }
  });

  const component = computed(() => {
    switch (props.componentType) {
      case 'link':
        return i18nLink
      case 'linkWithFallback':
        return i18nLinkWithFallback
      case 'nuxtLink':
        return NuxtLink
      case 'a':
        return 'a'
      default:
        return 'button'
    }
  })
</script>

<style module lang=scss>
.root {
  composes: reset-button reset-link from global;

  padding: var(--padding--button--top) var(--padding--button--right) var(--padding--button--bottom) var(--padding--button--left);
  border-radius: 9999px;

  transition: background-color var(--transition--default), border var(--transition--default), color var(--transition--default);

  display: inline-flex;
  align-items: center;
  gap: .75ch;

  color: var(--pill--color--base);
  background-color: var(--pill--background-color--base);
  border: var(--pill--border--base, none);

  font-size: var(--font-x-small--font-size);
  line-height: var(--font-x-small--line-height);
  letter-spacing: var(--font-x-small--letter-spacing);
  --fs: var(--font-x-small--font-size);
  --lh: var(--font-x-small--line-height);

  letter-spacing: var(--font-x-small--letter-spacing);

  min-height: calc(var(--lh) + var(--padding--button--top) + var(--padding--button--bottom));
}

.root[data-uppercase] {
  text-transform: uppercase;
}

.root:focus {
  outline: none
}

.root[data-size-option="narrow"] {
  padding: var(--padding--button-narrow--top) var(--padding--button-narrow--right) var(--padding--button-narrow--bottom) var(--padding--button-narrow--left);

  font-size: var(--font-x-small--font-size);
  line-height: var(--font-x-small--line-height);
  letter-spacing: var(--font-x-small--letter-spacing);
  --fs: var(--font-x-small--font-size);
  --lh: var(--font-x-small--line-height);

  letter-spacing: var(--font-x-small--letter-spacing);

  min-height: calc(var(--lh) + var(--padding--button-narrow--top) + var(--padding--button-narrow--bottom));
}

.root[data-size-option="tiny"] {
  padding: var(--padding--button-tiny--top) var(--padding--button-tiny--right) var(--padding--button-tiny--bottom) var(--padding--button-tiny--left);

  font-size: var(--font-xx-small--font-size);
  line-height: var(--font-xx-small--line-height);
  letter-spacing: var(--font-xx-small--letter-spacing);
  --fs: var(--font-xx-small--font-size);
  --lh: var(--font-xx-small--line-height);

  letter-spacing: var(--font-xx-small--letter-spacing);

  min-height: calc(var(--lh) + var(--padding--button-tiny--top) + var(--padding--button-tiny--bottom));
}

.root[data-view-option="default"] {
  --pill--color--base: var(--pill--color, var(--color--black));
  --pill--background-color--base: var(--pill--background-color, var(--color--white));
  --pill--border--base: var(--stroke--default) solid var(--pill--border-color, var(--color--gray));

  &:focus,
  &:hover {
    --pill--color--base: var(--pill--color--hover, var(--color--black));
    --pill--background-color--base: var(--pill--background-color--hover, var(--color--gray));
    --pill--border--base: var(--stroke--default) solid var(--pill--border-color--hover, var(--color--gray));
  }
  &[data-active] {
    --pill--background-color--base: var(--pill--background-color--active, var(--color--gray--dark));
    --pill--border--base: var(--stroke--default) solid var(--pill--border-color--active, var(--color--gray--dark));
    --pill--color--base: var(--pill--color--active, var(--color--white));
  }
}

.root[data-view-option="primary"] {
  --pill--background-color--base: var(--pill--background-color, var(--color--blue));
  --pill--color--base: var(--color--white);

  &:focus,
  &:hover {
    --pill--color--base: var(--pill--color--hover, var(--color--white));
    --pill--background-color--base: var(--pill--background-color--hover, var(--color--gray--dark));
  }

  &[data-active] {
    --pill--background-color--base: var(--pill--background-color--active, var(--color--gray--dark));
    --pill--border--base: var(--stroke--default) solid var(--pill--border-color--active, var(--color--gray--dark));
    --pill--color--base: var(--pill--color--active, var(--color--white));
  }
}

.root[data-view-option="secondary"] {
  --pill--background-color--base: var(--color--white);
  --pill--color--base: var(--color--blue);
  --pill--border--base: var(--stroke--default) solid var(--color--blue);

  &:focus,
  &:hover {
    --pill--color--base: var(--pill--color--hover, var(--color--white));
    --pill--color--base: var(--color--white);
    --pill--background-color--base: var(--color--blue);
  }

  &[data-active] {
    --pill--background-color--base: var(--pill--background-color--active, var(--color--gray--dark));
    --pill--border--base: var(--stroke--default) solid var(--pill--border-color--active, var(--color--gray--dark));
    --pill--color--base: var(--pill--color--active, var(--color--white));
  }
}

.root[data-view-option="reversed"] {
  --pill--color--base: var(--color--primary);
  --pill--background-color--base: var(--color--background);
  --pill--border--base: var(--stroke--default) solid var(--color--primary);

  &:focus,
  &:hover {
    --pill--color--base: var(--color--background);
    --pill--background-color--base: var(--color--primary);
  }

  &[data-active] {
    --pill--background-color--base: var(--pill--background-color--active, var(--color--gray--dark));
    --pill--border--base: var(--stroke--default) solid var(--pill--border-color--active, var(--color--gray--dark));
    --pill--color--base: var(--pill--color--active, var(--color--white));
  }
}

.root[disabled] {
  pointer-events: none;
  --pill--background-color--base: var(--color--white);
  --pill--color--base: var(--color--gray);
  --pill--border--base: var(--stroke--default) solid var(--color--gray);
}
</style>
